<template>
  <v-main>

    <v-container
        id="users-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-list-box-outline"
          class="px-5 py-5"
      >


        <v-container fill-height fluid>

          <v-row align="center">
            <v-col cols="8" sm="2" md="2" class="text">
              Invoice Status:
            </v-col>
            <v-col cols="8" sm="2" md="2">
              <v-checkbox v-model="statusIds" label="Open" :value="1" />
            </v-col>
            <v-col cols="8" sm="2" md="2">
              <v-checkbox v-model="statusIds" label="Paid" :value="2"  />
            </v-col>
            <v-col cols="8" sm="2" md="2">
              <v-checkbox v-model="statusIds" label="Admin Review" :value="0"/>
            </v-col>
            <v-col cols="8" sm="2" md="2">
              <div class="my-2">
                <vue-csv-downloader
                    :data="csvData"
                    download-name="TilyetInvoicesExport.csv"
                >
                  <v-btn
                    color="primary"
                  >
                    Download CSV
                  </v-btn>
                </vue-csv-downloader>
              </div>
            </v-col>
          </v-row>

        </v-container>

        <v-card-text v-if="invoices">



          <v-simple-table
              fixed-header
          >
            <template v-slot:default>
              <thead>
              <tr>
                <th class="invoice-num text-subtitle-2">Invoice #</th>
                <th class="bill-to text-subtitle-2">Bill To</th>
                <th class="invoice-amount text-subtitle-2 text-right">Amount</th>
                <th class="invoice-balance text-subtitle-2 text-right">Balance</th>
                <th class="invoice-date text-subtitle-2">Date</th>
                <th class="due-date text-subtitle-2">Due Date</th>
                <th class="invoice-status text-subtitle-2">Status</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="invoice in invoices"
                  :key="invoice.id"
              >
                <td>
                  <router-link :to="{ name: 'view_invoice', params: {invoiceId: invoice.id} }">
                    {{ invoice.id }}
                  </router-link>
                </td>
                <td>
                  {{ invoice.user.firstName }} {{ invoice.user.middleName }} {{ invoice.user.lastName }}
                </td>
                <td class="text-right">{{ invoice.total | formatCurrency }}</td>
                <td class="text-right">{{ invoice.balance | formatCurrency }}</td>
                <td>{{ invoice.invoiceDate | formatDate }}</td>
                <td>{{ invoice.dueDate | formatDate }}</td>
                <td>{{ invoiceStatusMap[invoice.status] }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-card-text>

      </base-material-card>


    </v-container>

  </v-main>
</template>

<script>
import api from '@/services/api'
import constants from '@/utils/constants'
import Constants from '../../../utils/constants'
import validation from '../../../utils/validation'
import VueCsvDownloader from 'vue-csv-downloader'

export default {
  name: 'CoreInvoiceReportView',

  components: {
    VueCsvDownloader
  },

  created() {
    this.statusIds = [
        Constants.INVOICE_STATUS_OPEN,
        Constants.INVOICE_STATUS_ADMIN_REVIEW
    ]
    this.fetchInvoices()
  },

  watch: {
    warnings: function () {
      scroll(0, 0)
    },
    errors: function () {
      scroll(0, 0)
    },
    statusIds: function () {
      this.validate()
    }
  },

  computed: {
    csvData: function () {
      return this.invoices.map((inv) => {
          let row = {
            num: inv.id,
            date: new Date(inv.invoiceDate).toLocaleDateString(),
            amount: inv.total.toFixed(2),
            balance: inv.balance.toFixed(2),
            name: inv.user.names,
            claim: null,
            status:  this.invoiceStatusMap[inv.status]
          }

          if (inv.claim != null) {
            row.claim = inv.claim.user.names
          }

          return row
      })
    }
  },

  methods: {
    validate() {
      if (this.statusIds.length === 0) {
        this.errors.push('At least one status must be selected')
        return
      }
      this.fetchInvoices()
    },

    fetchInvoices() {
      let payload = {
        statusIds: this.statusIds
      }

      api
          .fetchInvoicesByStatuses(payload)
          .then(response => {
            this.invoices = response.data
            if (this.invoices.length === 0) {
              this.warnings.push('No invoices found matching criteria')
            }
          })
          .catch(err => {
            this.errors.push(api.parseResponseError(err))
          })
    }
  },

  data() {
    return {
      valid: true,
      errors: [],
      warnings: [],
      statusIds: null,
      firstName: null,
      lastName: null,
      invoices: [],
      invoiceStatusMap: constants.getInvoiceStatusMap(),
      invoiceTypes: constants.getInvoiceTypes(),
      rules: {
        required: [
          v => validation.isRequired(v) || 'required'
        ],
      }
    }
  },
}
</script>

<style lang="sass">
th.invoice-amount, th.invoice-balance, th.invoice-status
  width: 10%

th.invoice-num, th.invoice-date, th.due-date, th.invoice-amount, th.invoice-status
  width: 10%

</style>